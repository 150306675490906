import React from "react"
import Layer from "../components/Layer"
import Seo from "../components/Seo"


const PrivacyPolicy = () => {
  return (
    <>
      <Seo title="Privacy Policy"/>
      <Layer>
        <main className="terms_and_conditions_main privacy_policy_main">
          <br />
          <h1 style={{ fontSize: 20 }}>
            <strong >SOLmate Privacy Policy</strong>
          </h1>
          <hr />
          <p>Version 2.4 effective 01 July 2021</p>
          <p>
            At SOLmate, we value your privacy and strive to protect your
            personal data. SOLmate will only collect and use your personal data
            in accordance with this Privacy Policy and our Terms of Use.
          </p>
          <p>
            Reference in this policy to "SOLmate", "we", "us", "our" or any
            similar term is to AK SOLWALLET SERVICES LTD (CIPC reg.no
            2019/570828/10), who is the data controller.
          </p>
          <p>
            <strong>This Privacy Policy explains:</strong>
          </p>
          <ul>
            <li>How we collect personal data</li>
            <li>How we use cookies</li>
            <li>How we use your personal data</li>
            <li>How we share personal data</li>
            <li>Security of your personal data</li>
            <li>Global company</li>
            <li>Additional matters relating to personal data</li>
          </ul>
          <h3>
            <strong>How we collect personal data</strong>
          </h3>
          <p>
            When we open and operate an account for you, provide you with our
            products and services, or communicate with you, we may collect your
            personal data. We do this in various ways, including:
          </p>
          <ul>
            <li>
              when you provide it to us such as when you sign up for a SOLmate
              account, use our products and services, or take part in customer
              surveys, competitions and promotions;
            </li>
            <li>
              when you communicate with us by email, chat, telephone or any
              other means, we collect the communication and any data provided in
              it;
            </li>
            <li>
              when you use the SOLmate platform we collect information on your
              transactions and other use of your SOLmate account;
            </li>
            <li>
              when we obtain information from third parties such as identity
              verification services, credit reference agencies, and regulatory
              and enforcement agencies.
            </li>
          </ul>
          <h3>
            <strong>How we use cookies</strong>
          </h3>
          <p>
            We also collect personal data through the use of cookies. Cookies
            (and other similar technologies) help us give you the best
            experience of using our site.
          </p>
          <p>
            Cookies are small data files that we or companies we work with may
            place on your computer or other devices when you visit our website.
            They allow us to remember your actions or preferences over time.
          </p>
          <p>We use cookies to collect data that helps us to:</p>
          <ul>
            <li>track site usage and browsing behaviour;</li>
            <li>
              allow you to sign in to your account and navigate through the
              website;
            </li>
            <li>
              tailor our websiteâ€™s functionality to you personally by letting
              us remember your preferences;
            </li>
            <li>improve how our website performs;</li>
            <li>allow third parties to provide services to our website;</li>
            <li>
              monitor the effectiveness of our promotions and advertising; and
            </li>
            <li>mitigate risk, enhance security and help prevent fraud.</li>
          </ul>
          <p>
            We use both session and persistent cookies. Session cookies are
            deleted when you close down your browser, while persistent cookies
            remain on your device until they expire or you delete them.
            Persistent cookies allow us to remember things about you when you
            visit our website again.
          </p>
          <h3>Third-party cookies</h3>
          <p>
            We may use third-party providers to help us determine which of our
            advertisements are most likely to be of interest to you. These
            providers may use behavioural information, such as how you navigate
            the internet, to provide relevant advertisements to you.
          </p>
          <p>
            We may also allow some of these companies to use tracking pixels.
            Tracking pixels may be used to collect and store information about
            visits to our website, such as which pages you viewed and how long
            you spent on the website, as well as the specific advertisement that
            you clicked to visit our site. No personally identifiable
            information is stored on these cookies. The information reported to
            us is aggregated and anonymous. We use this information to
            understand, for example, the effectiveness of our advertising and
            marketing.
          </p>
          <p>
            Overall, cookies help us provide you with a better website, by
            enabling us to monitor which pages you find useful and which you do
            not. A cookie in no way gives us access to your computer or any
            information about you, other than the data you choose to share with
            us.
          </p>
          <p>
            By signing up for an account with SOLmate, or continuing to use our
            website, you agree to our use of cookies as set out in this policy.
            You may decline our cookies if your browser or browser add-on
            permits, but doing so may interfere with your use of SOLmate â€˜s
            services. For information on how to delete or reject cookies, you
            can consult the â€œhelpâ€ function within your browser, or visit
            <a href="https://www.google.com/url?q=http://www.allaboutcookies.org/&amp;sa=D&amp;source=editors&amp;ust=1625752486891000&amp;usg=AOvVaw0ta94pECny4YY7_HjH01ZU">
              www.allaboutcookies.org
            </a>
            , where you will also find more information data about cookies
            generally.
          </p>
          <h3>How we use your personal data</h3>
          <p>We use personal data for one or more of the following purposes:</p>
          <ul>
            <li>
              to verify your identity in accordance with Know Your Customer
              (KYC), Anti-Money Laundering (AML) and sanctions screening
              requirements;
            </li>
            <li>to manage and maintain your account with us;</li>
            <li>
              to prevent fraudulent or unauthorised use of our products and
              services;
            </li>
            <li>
              to better manage our business and your relationship with us;
            </li>
            <li>
              to improve our products and services, and to develop new products
              and services;
            </li>
            <li>
              to notify you about benefits and changes to the features of our
              products and services;
            </li>
            <li>to provide you with personalised advertising and marketing;</li>
            <li>to respond to your enquiries and to resolve disputes.</li>
          </ul>
          <p>
            Where necessary to protect our legal rights and interest, or the
            interests of others, we also use personal data in relation to legal
            claims, compliance, audit, risk management and regulatory functions.
            We may also use personal data in connection with the acquisition,
            merger or sale of a business.
          </p>
          <h3>How we share personal data</h3>
          <p>We share personal data with:</p>
          <ul>
            <li>
              any person that works for us or for one of our group companies;
            </li>
            <li>
              any entity that forms part of the SOLmate group of companies,
              including where relevant the SOLmate operating entity in the
              country or region in which you live;
            </li>
            <li>
              financial and other institutions we partner with to provide our
              products and services;
            </li>
            <li>
              companies and organisations that provide services to us, including
              in relation to technical infrastructure, marketing and analytics,
              and web and app development;
            </li>
            <li>
              companies and organisations that assist us with identity
              verification, background screening, due diligence and processing
              or otherwise fulfilling transactions that you have requested;
            </li>
            <li>
              our professional advisers, consultants and other similar services.
            </li>
          </ul>
          <p>
            We will otherwise treat your personal data as private and
            confidential and will not share it with other parties except:
          </p>
          <ul>
            <li>where you have given permission;</li>
            <li>
              where we believe it is reasonably necessary to comply with any
              law, regulation, legal process or governmental request, to enforce
              our Terms of Use or other agreements, or to protect the rights,
              property, or safety of us, our customers or others;
            </li>
            <li>
              where we may transfer rights and obligations pursuant to our
              agreement with you.
            </li>
          </ul>
          <h3>Security of your personal data</h3>
          <p>
            SOLmate places great importance on ensuring the security of your
            personal data. We regularly review and implement up-to-date
            technical and organisational security measures when processing your
            personal data. Employees of SOLmate are trained to handle personal
            data securely and with the utmost respect, failing which they may be
            subject to disciplinary action.
          </p>
          <h3>Global company</h3>
          <p>
            The personal data we collect may be transferred to, stored and
            processed outside of the jurisdiction in which you reside, and the
            laws of those countries may differ from the laws applicable in your
            own country. For example, data collected in the South Africa (SA)
            may be transferred to, stored and processed at a destination(s)
            outside of the SA. Any processing of such data will be undertaken by
            our staff, or the staff of our third-party service providers, whose
            roles will include verifying your identity, processing payment
            details, and providing customer support.
          </p>
          <p>
            By accepting this Privacy Policy, and then submitting your personal
            data, you agree to the transfer, storing or processing of it outside
            of your jurisdiction.
          </p>
          <h3>Additional matters relating to personal data</h3>
          <p>
            <strong>Retention of personal data</strong>
          </p>
          <p>
            SOLmate will retain your personal data, in compliance with this
            Privacy Policy and the Terms of Use, for the duration of your
            relationship with us, and afterwards for such period as may be
            necessary for our legitimate business purposes (including compliance
            with our legal obligations, preventing fraud, resolving disputes and
            enforcing agreements), to protect the interests of SOLmate and its
            customers, and as required by law.
          </p>
          <p>
            <strong>Incomplete personal data</strong>
          </p>
          <p>
            Where indicated (for example in application forms or account opening
            forms), it is obligatory to provide your personal data to us to
            enable us to process your application for our products or services.
            Should you decline to provide such personal data, we may not be able
            to process your application/request or provide you with our products
            or services.
          </p>
          <p>
            <strong>
              Your right to access, update, or remove your personal data
            </strong>
          </p>
          <p>
            Most of the data SOLmate collects, and the ways in which we use it,
            are necessary for us to provide and improve the services we provide
            to you, or to comply with our obligations. In certain situations, we
            give you the ability to choose how we use your data.
          </p>
          <p>
            Depending on the country in which you live, you may have certain
            rights under data protection law, including the right to object to
            the processing of your personal data or to request that we:
          </p>
          <ul>
            <li>
              provide you with a copy of your personal data (including in a
              format that can be shared with a new provider); or
            </li>
            <li>
              correct, delete, or restrict the processing of your personal data.
            </li>
          </ul>
          <p>
            Please submit a support ticket if you would like to exercise any of
            the above rights. These rights are limited in some situations, such
            as where we are legally required to process your data, and may limit
            your ability to use our products and services.
          </p>
          <p>
            <strong>Data breaches</strong>
          </p>
          <p>
            We will notify you and the relevant supervisory authority as soon as
            we become aware of any data breach that is likely to result in a
            risk to your rights and freedoms.
          </p>
          <p>
            <strong>Revisions to this Privacy Policy</strong>
          </p>
          <p>
            We may amend this Privacy Policy from time to time. We will do our
            best to notify you of any amendments to the Privacy Policy and any
            such notice will be posted on our application or our website, or
            sent by email to the address associated with your SOLmate Account.
          </p>
          <p>
            <strong>Contacting SOLmate in relation to personal data</strong>
          </p>
          <p>
            Should you have any query in relation to this Privacy Policy or how
            we handle your personal data, please contact us by an email to
            support@solmate.co.za
          </p>

          <div id="volume-booster-visusalizer">
            <div class="sound">
              <div class="sound-icon"></div>
              <div class="sound-wave sound-wave_one"></div>
              <div class="sound-wave sound-wave_two"></div>
              <div class="sound-wave sound-wave_three"></div>
            </div>
            <div class="segments-box">
              <div data-range="1-20" class="segment">
                <span></span>
              </div>
              <div data-range="21-40" class="segment">
                <span></span>
              </div>
              <div data-range="41-60" class="segment">
                <span></span>
              </div>
              <div data-range="61-80" class="segment">
                <span></span>
              </div>
              <div data-range="81-100" class="segment">
                <span></span>
              </div>
            </div>
          </div>
        </main>
      </Layer>
    </>
  )
}

export default PrivacyPolicy
